<template>
  <v-container class="pa-8" fluid>
    <v-card class="primary  gradient primary lighten-1 pb-3" elevation="0" outlined>
      <v-card-text class="pa-0 pl-4">
        <v-row>
          <v-col cols="12" md="8" sm="12">
            <v-card-title class="text-white small pb-1">
              <div class="text-left d-flex">
                <v-icon
                    class="pr-2"
                    color="white"
                    large
                >
                  mdi-calendar-range
                </v-icon>
                <div>
                  <span v-if="!this.$vuetify.breakpoint.xsOnly">{{ this.firstday }} - {{ this.lastday }}</span>
                  <span v-else>
                  <div>
                    {{ this.firstday }}
                  </div>
                  <div>
                    {{ this.lastday }}
                  </div>
                </span>
                </div>
              </div>
            </v-card-title>

            <v-card-title class="text-white pt-0">
              <v-icon
                  class="pr-2"
                  color="white"
                  large
              >
                mdi-wallet
              </v-icon>
              <span v-if="!this.loading">{{ (this.user.net_credit - this.currentWeekTotal) | currency }}</span>
              <span v-else>
                <v-progress-circular color="white" indeterminate/>
              </span>
            </v-card-title>

            <v-card-text class="text-justify text-white">
              <p>{{ note && note.message }}</p>
            </v-card-text>
            <v-card-actions class="pt-0 pb-0">
              <v-btn class="pl-0" icon @click="setCurrentWeek(-1)">
                <v-icon
                    color="white"
                    x-large
                >
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                  color="white"
                  outlined
                  @click="setCurrentWeek(0) & reloadWeek()"
              >
                {{ $t('generic.lang_today') }}
              </v-btn>
              <v-btn icon @click="setCurrentWeek(1)">
                <v-icon
                    color="white"
                    x-large
                >
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col v-if="this.$vuetify.breakpoint.mdAndUp" cols="12" md="4" sm="12">
            <v-img
                :src="this.note_image"
                style="position: absolute; bottom: 15px; right: 0"
                width="340px"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-8 right-border-radius" elevation="0">
      <v-card-text class="pa-0 right-border-radius">
        <menu-data-table
            ref="orders"
            v-if="this.curr"
            v-bind:first-day="this.firstday"
            v-bind:curr="curr"
            v-bind:items="products"
            v-bind:orders="orders"
            v-bind:allergens="allergens"
        />
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>

import {createNamespacedHelpers} from 'vuex';
import ENDPOINTS from "@/plugins/axios/endpoints";
import moment from "moment-timezone";

export default {
  name: "index",
  components: {
    'menu-data-table': require('@/components/dashboard/Menu/MenuDataTable').default
  },
  data: () => {
    return {
      note_image: '',
      allergens: [],
      today: '',
      firstday: '',
      lastday: '',
      curr: null,
      selectedItems: {},
      tabs: 0,
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      note: {},
      products: null,
      orders: null
    }
  },
  computed: {
    ...createNamespacedHelpers('auth').mapGetters({
      user: 'getUser'
    }),
    ...createNamespacedHelpers('order').mapGetters(["total"]),
    ...createNamespacedHelpers('loader').mapGetters([
      "loading"
    ]),
    currentWeekTotal() {
      return parseFloat(this.total) - parseFloat(this.oldTotal);
    },
    oldTotal() {
      if (Array.isArray(this.orders) && this.orders.length > 0) {
        return this.orders.reduce((total, order) => total + order.total, 0);
      } else return 0
    },

    headers() {
      const days = []
      for (let i = 0; i < 6; i++) {
        days.push({
          text: this.getDate(i + 1).split(' ')[0],
          date: this.getDate(i + 1).split(' ')[1],
          value: this.weekDays[i].toLowerCase(),
          align: 'center',
          sortable: false
        })
      }
      return days
    }
  },
  async beforeRouteEnter(to, from, next) {
    /**
     * init dates
     * */
    let curr = new Date()
    curr.setHours(0, 0, 0, 0)

    /* let first = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week
     curr.setDate(first)
     curr = new Date(curr.getTime() + (24 * 60 * 60 * 1000))
     first = curr.getDate() - curr.getDay() + 1
     const last = first + 5;*/


    var start = moment(curr.getTime()).startOf('week').unix(),
        end = moment(curr.getTime()).endOf('week').unix();//Math.round(new Date(curr.setDate(last)).getTime() / 1000);

    //load note of the current day ( configured in pos )
    const note = await window.axios.get(ENDPOINTS.CANTEEN.SETTINGS.NOTE, {
      params: {
        day: Math.floor(new Date().getTime() / 1000)
      }
    }).then((res) => res.data.data)
    //load menu for the current week
    const products = await window.axios.get(ENDPOINTS.CANTEEN.MENU.GET, {
      params: {
        start: start,
        end: end
      }
    }).then((res) => res.data.data)
    //load ordered items for logged user in the current week
    const orders = await window.axios.get(ENDPOINTS.CANTEEN.ORDER.GET, {
      params: {
        start: start,
        end: end
      }
    }).then((res) => res.data.data)
    //add orders to vuex

    //load logged user allergens
    const allergens = await window.axios.get(ENDPOINTS.CANTEEN.ALLERGEN.USER.GET).then((res) => res.data.data);

    //init data
    next(vm => vm.initData(note, products, orders, allergens));
  },
  mounted() {
  },
  methods: {
    /*map order  module mutations */
    ...createNamespacedHelpers('order').mapMutations([
      "clear"
    ]),
    /**
     * load user orders and menu for selected week
     * @returns {Promise<void>}
     */
    async reloadWeek() {
      this.clear();
      /**
       * init dates based on current date
       * */
      let curr = new Date(this.curr)
      curr.setHours(0, 0, 0, 0);


      let start = moment(curr.getTime()).startOf('week').unix(),
          end = moment(curr.getTime()).endOf('week').unix();

      //load menu/items for selected week
      const products = await window.axios.get(ENDPOINTS.CANTEEN.MENU.GET, {
        params: {
          start: start,
          end: end
        }
      }).then((res) => res.data.data)
      //load ordered items by the logged user for selected week
      const orders = await window.axios.get(ENDPOINTS.CANTEEN.ORDER.GET, {
        params: {
          start: start,
          end: end
        }
      }).then((res) => res.data.data);


      await this.$store.dispatch('auth/userDetails');

      this.products = products;
      this.setOrders(orders)
    },
    /**
     * @param note
     * @param products
     * @param orders
     * @param allergens
     */
    initData(note, products, orders, allergens) {
      this.setNote(note);
      this.setProducts(products)
      this.setOrders(orders)
      this.setAllergens(allergens);
      this.setCurrentWeek();
      //get note image from config state
      this.note_image = '' + this.$store.getters['config/getImages'].note_image + '';
    },
    /**
     * @param allergens
     */
    setAllergens(allergens) {
      this.allergens = allergens
    },
    /**
     * @param products
     */
    /**
     * @param products
     */
    setProducts(products) {
      this.products = []
      this.products = products;
    },
    setOrders(orders) {
      this.orders = []
      this.orders = [...orders];

      if (this.$refs.orders) {
        this.$refs.orders.addOldOrders(this.orders)
        this.$refs.orders.$forceUpdate();
      }
      this.$forceUpdate();
    },
    setNote(note) {
      this.note = {}
      this.note = note;
    },
    selectItem(item) {
      //search requested item
      const index = this.products.indexOf(item)

      /*
      * let's get info about the day where the item is selected
      * */
      const day = this.tabs + 1

      const dtkey = this.curr.getFullYear() + '-' + (this.curr.getMonth() + 1) + '-' + this.getDay(day).split(' ')[1]
      if (item.selected) {
        /* if the item is already selected we'll unselect it */

      } else if (this.selectedItems[dtkey] !== undefined) {
        this.selectedItems[dtkey].push(item)
      } else {
        this.selectedItems[dtkey] = []
        this.selectedItems[dtkey].push(item)
      }
      item.selected = !item.selected
      Object.assign(this.products[index], item)
      this.products = this.products.map(elt => elt)
    },
    getDate(i) {
      let dt = new Date(this.curr.getTime())
      const first = dt.getDate() - dt.getDay()
      dt.setDate(first)
      dt = new Date(dt.getTime() + (i * 24 * 60 * 60 * 1000))
      return this.weekDays[dt.getDay() - 1] + ' ' + dt.toLocaleDateString().split('/').reverse().join('-')
    },
    getDay(i) {
      let dt = new Date(this.curr.getTime())
      const first = dt.getDate() - dt.getDay()
      dt.setDate(first)
      dt = new Date(dt.getTime() + (i * 24 * 60 * 60 * 1000))

      return dt.toDateString().split(' ')[0] + ' ' + dt.toDateString().split(' ')[2]
    },
    /**
     * THIS FUNCTION ALLOW US TO SWITCH / NAVIGATE BETWEEN WEEKS
     * @param next
     */
    setCurrentWeek(next = 0) {
      if (this.curr === null || next === 0) {
        this.curr = new Date()
        this.curr.setHours(0, 0, 0, 0);
        this.firstday = moment(this.curr.getTime()).startOf("week").format("dddd MMMM DD YYYY");
        this.lastday = moment(this.curr.getTime()).endOf("week").format("dddd MMMM DD YYYY")
      }

      if (next > 0) {

        this.curr = new Date(moment(this.curr.getTime()).startOf("week").add(7, 'days').valueOf());
      } else if (next < 0) {

        this.curr = new Date(moment(this.curr.getTime()).startOf("week").add(-7, 'days').valueOf());
      }

      this.firstday = moment(this.curr.getTime()).startOf("week").format("dddd MMMM DD YYYY");
      this.lastday = moment(this.curr.getTime()).endOf("week").format("dddd MMMM DD YYYY")
      if (next === 1 || next === -1)
        this.reloadWeek()
    }
  }
}
</script>

<style scoped>

</style>