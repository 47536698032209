<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-alert v-if="(user.net_credit - currentWeekTotal) < 0" dismissible color="warning" class="white--text">
      Please refill your account to order
    </v-alert>
    <!--    <v-alert class="white&#45;&#45;text" color="error" dismissible v-model="errorVisible">
          {{ this.errorMessage }}
        </v-alert>-->
    <v-data-table
        v-if="!this.$vuetify.breakpoint.xsOnly"
        class="align-top dt"
        v-bind:headers="headers"
        :items="this.filteredItems"
        :items-per-page="30"
        hide-default-footer
    >
      <template v-slot:header.monday="{header}">
        <v-alert color="grey darken-3" dense class="text-white font-weight-light pb-1 pt-1 mb-1">
          <div>{{ header.text }}</div>
          <div>{{ header.date }}</div>
        </v-alert>
        <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
          Stornofrist {{ getDateToOrder(header.date) }}
        </v-alert>

        <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
          Bestellfrist {{ getDateToCancel(header.date) }}
        </v-alert>
      </template>

      <template v-slot:header.tuesday="{header}">
        <v-alert color="grey darken-3" dense class="text-white font-weight-light pb-1 pt-1 mb-1">
          <div>{{ header.text }}</div>
          <div>{{ header.date }}</div>
        </v-alert>
        <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
          Stornofrist {{ getDateToOrder(header.date) }}
        </v-alert>

        <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
          Bestellfrist {{ getDateToCancel(header.date) }}
        </v-alert>
      </template>

      <template v-slot:header.wednesday="{header}">
        <v-alert color="grey darken-3" dense class="text-white font-weight-light pb-1 pt-1 mb-1">
          <div>{{ header.text }}</div>
          <div>{{ header.date }}</div>
        </v-alert>
        <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
          Stornofrist {{ getDateToOrder(header.date) }}
        </v-alert>

        <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
          Bestellfrist {{ getDateToCancel(header.date) }}
        </v-alert>
      </template>
      <template v-slot:header.thursday="{header}">
        <v-alert color="grey darken-3" dense class="text-white font-weight-light pb-1 pt-1 mb-1">
          <div>{{ header.text }}</div>
          <div>{{ header.date }}</div>
        </v-alert>
        <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
          Stornofrist {{ getDateToOrder(header.date) }}
        </v-alert>

        <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
          Bestellfrist {{ getDateToCancel(header.date) }}
        </v-alert>
      </template>
      <template v-slot:header.friday="{header}">
        <v-alert color="grey darken-3" dense class="text-white font-weight-light pb-1 pt-1 mb-1">
          <div>{{ header.text }}</div>
          <div>{{ header.date }}</div>
        </v-alert>
        <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
          Stornofrist {{ getDateToOrder(header.date) }}
        </v-alert>

        <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
          Bestellfrist {{ getDateToCancel(header.date) }}
        </v-alert>
      </template>
      <template v-slot:header.saturday="{header}">
        <v-alert color="grey darken-3" dense class="text-white font-weight-light pb-1 pt-1 mb-1">
          <div>{{ header.text }}</div>
          <div>{{ header.date }}</div>
        </v-alert>
        <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
          Stornofrist {{ getDateToOrder(header.date) }}
        </v-alert>

        <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
          Bestellfrist {{ getDateToCancel(header.date) }}
        </v-alert>
      </template>

      <template v-slot:item.monday="{item}" class="align-top" style="min-height: 400px !important;">
        <v-card
            v-for="(elt ,i) in item['monday']"
            :key="i"
            class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
            elevation="0"
            min-width="100%"
            outlined
            rounded
            v-bind:class="is_selected({
              item: elt,
              day: 'monday'
            })? 'border-success' : ''"
        >
          <!--          <v-simple-checkbox :true-value="true" :disabled="hasAllergen(elt)"
                                       :value="is_selected({
                                item:elt,
                                day: 'monday'
                                })"
                                       v-model="elt.selected"
                                       color="primary"
                                       style="position:absolute;top:-20px;left: 0"
                                       @change="selectChanged($event , elt , 'monday')"
                    />-->

          <v-btn :disabled="(hasAllergen(elt) || is_expired('monday')) || (hasAllergen(elt) && is_expired('monday'))"
                 @click="selectChanged(false , elt , 'monday')"
                 width="20" height="20"
                 outlined class="pa-0 px-0"
                 style="position:absolute;top:2px;left: 2px"
                 color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: 'monday'
            })">
            <v-icon class="ma-0" small>mdi-check
            </v-icon>
          </v-btn>

          <v-btn :disabled="(hasAllergen(elt) || is_expired('monday')) || (hasAllergen(elt) && is_expired('monday'))"
                 @click="selectChanged(true , elt , 'monday')"
                 width="20" height="20"
                 outlined class="pa-0 px-0"
                 style="position:absolute;top:2px;left: 2px"
                 color="grey" elevation="0" fab x-small v-else>
          </v-btn>


          <h6
              class="font-weight-bolder mt-0 font-size-md mx-auto"
              style="position:absolute;top:3px;right: 4px; font-family: Impact;"
          >
            {{
              elt.price | currency
            }}
          </h6>
          <v-card-text class="pa-2 mt-5">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col
                  class="pb-0 pointer text-left pt-1 ma-0"
                  cols="12"
              >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                <div class="text-muted">
                  {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                </div>
              </v-col>

              <v-col class="text-right d-flex">
                <v-spacer/>
                <v-btn
                    class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                    height="22"
                    icon
                    small
                    tile
                    width="22"
                    @click="showAllergen(elt)"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-information-variant
                  </v-icon>
                </v-btn>

                <div v-if="Array.isArray(elt.types)">

                  <v-btn v-for="type in elt.types" :key="type.name"
                         class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                         height="22"
                         icon
                         small
                         tile
                         width="22"
                  >
                    <v-icon class="primary--text pa-3 bg-grey">
                      mdi-{{ type.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    tile
                    small
                    width="25"
                    :disabled="(hasAllergen(elt) || is_expired('monday')) || (hasAllergen(elt) && is_expired('monday'))"
                    @click="minus({
                    item: elt , day: 'monday'
                    })"
                >
                  <v-icon class="primary--text font-weight-bold mx-auto" large>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: "monday"
                        })
                      }}</span>
                  </span>

                <span class="ml-2" style="font-size:10px">{{
                    price({
                      uuid: elt.uuid,
                      day: "monday"
                    }) | currency
                  }}</span>
              </v-col>
              <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    small
                    tile
                    width="25"
                    @click="selectItem(elt , 'monday')"
                    :disabled="(hasAllergen(elt) || is_expired('monday')) || (hasAllergen(elt) && is_expired('monday'))"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:item.tuesday="{item}" class="align-top" style="min-height: 400px !important;">
        <v-card
            v-for="(elt ,i) in item['tuesday']"
            :key="i"
            class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
            elevation="0"
            min-width="100%"
            outlined
            v-bind:class="is_selected({
              item: elt,
              day: 'tuesday'
            })? 'border-success' : ''"
        >
          <v-btn :disabled="(hasAllergen(elt) || is_expired('tuesday')) || (hasAllergen(elt) && is_expired('tuesday'))"
                 @click="selectChanged(false , elt , 'tuesday')" width="20" height="20"
                 outlined class="pa-0 px-0"
                 style="position:absolute;top:2px;left: 2px"
                 color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: 'tuesday'
            })">
            <v-icon class="ma-0" small>mdi-check
            </v-icon>
          </v-btn>

          <v-btn :disabled="(hasAllergen(elt) || is_expired('tuesday')) || (hasAllergen(elt) && is_expired('tuesday'))"
                 @click="selectChanged(true , elt , 'tuesday')" width="20" height="20"
                 outlined class="pa-0 px-0"
                 style="position:absolute;top:2px;left: 2px"
                 color="grey" elevation="0" fab x-small v-else>
          </v-btn>

          <h6
              class="font-weight-bolder mt-0 font-size-md mx-auto"
              style="position:absolute;top:3px;right: 4px; font-family: Impact;"
          >
            {{
              elt.price | currency
            }}
          </h6>
          <v-card-text class="pa-2 mt-5">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col
                  class="pb-0 pointer text-left pt-1 ma-0"
                  cols="12"
              >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                <div class="text-muted">
                  {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                </div>
              </v-col>

              <v-col class="text-right d-flex">
                <v-spacer/>
                <v-btn
                    class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                    height="22"
                    icon
                    small
                    tile
                    width="22"
                    @click="showAllergen(elt)"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-information-variant
                  </v-icon>
                </v-btn>

                <div v-if="Array.isArray(elt.types)">

                  <v-btn v-for="type in elt.types" :key="type.name"
                         class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                         height="22"
                         icon
                         small
                         tile
                         width="22"
                  >
                    <v-icon class="primary--text pa-3 bg-grey">
                      mdi-{{ type.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    tile
                    small
                    width="25"
                    @click="minus({
                    item: elt , day: 'tuesday'
                    })"
                    :disabled="(hasAllergen(elt) || is_expired('tuesday')) || (hasAllergen(elt) && is_expired('tuesday'))"
                >
                  <v-icon class="primary--text font-weight-bold mx-auto" large>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: "tuesday"
                        })
                      }}</span>
                  </span>

                <span class="ml-2" style="font-size:10px">{{
                    price({
                      uuid: elt.uuid,
                      day: "tuesday"
                    }) | currency
                  }}</span>
              </v-col>
              <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    small
                    tile
                    width="25"
                    :disabled="(hasAllergen(elt) || is_expired('tuesday')) || (hasAllergen(elt) && is_expired('tuesday'))"
                    @click="selectItem(elt , 'tuesday')"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:item.wednesday="{item}" class="align-top" style="min-height: 400px !important;">
        <v-card
            v-for="(elt ,i) in item['wednesday']"
            :key="i"
            class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
            elevation="0"
            min-width="100%"
            outlined
            v-bind:class="is_selected({
              item: elt,
              day: 'wednesday'
            })? 'border-success' : ''"
        >
          <v-btn
              :disabled="(hasAllergen(elt) || is_expired('wednesday')) || (hasAllergen(elt) && is_expired('wednesday'))"
              @click="selectChanged(false , elt , 'wednesday')" width="20" height="20"
              outlined class="pa-0 px-0"
              style="position:absolute;top:2px;left: 2px"
              color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: 'wednesday'
            })">
            <v-icon class="ma-0" small>mdi-check
            </v-icon>
          </v-btn>

          <v-btn
              :disabled="(hasAllergen(elt) || is_expired('wednesday')) || (hasAllergen(elt) && is_expired('wednesday'))"
              @click="selectChanged(true , elt , 'wednesday')" width="20" height="20"
              outlined class="pa-0 px-0"
              style="position:absolute;top:2px;left: 2px"
              color="grey" elevation="0" fab x-small v-else>
          </v-btn>

          <h6
              class="font-weight-bolder mt-0 font-size-md mx-auto"
              style="position:absolute;top:3px;right: 4px; font-family: Impact;"
          >
            {{
              elt.price | currency
            }}
          </h6>
          <v-card-text class="pa-2 mt-5">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col
                  class="pb-0 pointer text-left pt-1 ma-0"
                  cols="12"
              >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                <div class="text-muted">
                  {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                </div>
              </v-col>

              <v-col class="text-right d-flex">
                <v-spacer/>
                <v-btn
                    class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                    height="22"
                    icon
                    small
                    tile
                    width="22"
                    @click="showAllergen(elt)"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-information-variant
                  </v-icon>
                </v-btn>

                <div v-if="Array.isArray(elt.types)">

                  <v-btn v-for="type in elt.types" :key="type.name"
                         class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                         height="22"
                         icon
                         small
                         tile
                         width="22"
                  >
                    <v-icon class="primary--text pa-3 bg-grey">
                      mdi-{{ type.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    tile
                    small
                    width="25"
                    @click="minus({
                    item: elt , day: 'wednesday'
                    })"
                    :disabled="(hasAllergen(elt) || is_expired('wednesday')) || (hasAllergen(elt) && is_expired('wednesday'))"
                >
                  <v-icon class="primary--text font-weight-bold mx-auto" large>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: "wednesday"
                        })
                      }}</span>
                  </span>

                <span class="ml-2" style="font-size:10px">{{

                    price({
                      uuid: elt.uuid,
                      day: "wednesday"
                    }) | currency
                  }}</span>
              </v-col>
              <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    small
                    tile
                    width="25"
                    :disabled="(hasAllergen(elt) || is_expired('wednesday')) || (hasAllergen(elt) && is_expired('wednesday'))"
                    @click="selectItem(elt , 'wednesday')"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:item.thursday="{item}" class="align-top" style="min-height: 400px !important;">
        <v-card
            v-for="(elt ,i) in item['thursday']"
            :key="i"
            class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
            elevation="0"
            min-width="100%"
            outlined
            v-bind:class="is_selected({
              item: elt,
              day: 'thursday'
            })? 'border-success' : ''"
        >
          <v-btn
              :disabled="(hasAllergen(elt) || is_expired('thursday')) || (hasAllergen(elt) && is_expired('thursday'))"
              @click="selectChanged(false , elt , 'thursday')"
              width="20" height="20"
              outlined class="pa-0 px-0"
              style="position:absolute;top:2px;left: 2px"
              color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: 'thursday'
            })">
            <v-icon class="ma-0" small>mdi-check
            </v-icon>
          </v-btn>

          <v-btn
              :disabled="(hasAllergen(elt) || is_expired('thursday')) || (hasAllergen(elt) && is_expired('thursday'))"
              @click="selectChanged(true , elt , 'thursday')"
              width="20" height="20"
              outlined class="pa-0 px-0"
              style="position:absolute;top:2px;left: 2px"
              color="grey" elevation="0" fab x-small v-else>
          </v-btn>

          <h6
              class="font-weight-bolder mt-0 font-size-md mx-auto"
              style="position:absolute;top:3px;right: 4px; font-family: Impact;"
          >
            {{
              elt.price | currency
            }}
          </h6>
          <v-card-text class="pa-2 mt-5">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col
                  class="pb-0 pointer text-left pt-1 ma-0"
                  cols="12"
              >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                <div class="text-muted">
                  {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                </div>
              </v-col>

              <v-col class="text-right d-flex">
                <v-spacer/>
                <v-btn
                    class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                    height="22"
                    icon
                    small
                    tile
                    width="22"
                    @click="showAllergen(elt)"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-information-variant
                  </v-icon>
                </v-btn>

                <div v-if="Array.isArray(elt.types)">

                  <v-btn v-for="type in elt.types" :key="type.name"
                         class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                         height="22"
                         icon
                         small
                         tile
                         width="22"
                  >
                    <v-icon class="primary--text pa-3 bg-grey">
                      mdi-{{ type.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    tile
                    small
                    width="25"
                    @click="minus({
                    item: elt , day: 'thursday'
                    })"
                    :disabled="(hasAllergen(elt) || is_expired('thursday')) || (hasAllergen(elt) && is_expired('thursday'))"
                >
                  <v-icon class="primary--text font-weight-bold mx-auto" large>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: "thursday"
                        })
                      }}</span>
                  </span>

                <span class="ml-2" style="font-size:10px">{{
                    price({
                      uuid: elt.uuid,
                      day: "thursday"
                    }) | currency
                  }}</span>
              </v-col>
              <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    small
                    tile
                    width="25"
                    :disabled="(hasAllergen(elt) || is_expired('thursday')) || (hasAllergen(elt) && is_expired('thursday'))"
                    @click="selectItem(elt , 'thursday')"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:item.friday="{item}" class="align-top" style="min-height: 400px !important;">
        <v-card
            v-for="(elt ,i) in item['friday']"
            :key="i"
            class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
            elevation="0"
            min-width="100%"
            outlined
            v-bind:class="is_selected({
              item: elt,
              day: 'friday'
            })? 'border-success' : ''"
        >

          <v-btn :disabled="(hasAllergen(elt) || is_expired('friday')) || (hasAllergen(elt) && is_expired('friday'))"
                 @click="selectChanged(false , elt , 'friday')" width="20" height="20"
                 outlined class="pa-0 px-0"
                 style="position:absolute;top:2px;left: 2px"
                 color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: 'friday'
            })">
            <v-icon class="ma-0" small>mdi-check
            </v-icon>
          </v-btn>

          <v-btn :disabled="(hasAllergen(elt) || is_expired('friday')) || (hasAllergen(elt) && is_expired('friday'))"
                 @click="selectChanged(true , elt , 'friday')" width="20" height="20"
                 outlined class="pa-0 px-0"
                 style="position:absolute;top:2px;left: 2px"
                 color="grey" elevation="0" fab x-small v-else>
          </v-btn>


          <h6
              class="font-weight-bolder mt-0 font-size-md mx-auto"
              style="position:absolute;top:3px;right: 4px; font-family: Impact;"
          >
            {{
              elt.price | currency
            }}
          </h6>
          <v-card-text class="pa-2 mt-5">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col
                  class="pb-0 pointer text-left pt-1 ma-0"
                  cols="12"
              >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                <div class="text-muted">
                  {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                </div>
              </v-col>

              <v-col class="text-right d-flex">
                <v-spacer/>
                <v-btn
                    class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                    height="22"
                    icon
                    small
                    tile
                    width="22"
                    @click="showAllergen(elt)"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-information-variant
                  </v-icon>
                </v-btn>

                <div v-if="Array.isArray(elt.types)">

                  <v-btn v-for="type in elt.types" :key="type.name"
                         class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                         height="22"
                         icon
                         small
                         tile
                         width="22"
                  >
                    <v-icon class="primary--text pa-3 bg-grey">
                      mdi-{{ type.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    tile
                    small
                    width="25"
                    @click="minus({
                    item: elt , day: 'friday'
                    })"
                    :disabled="(hasAllergen(elt) || is_expired('friday')) || (hasAllergen(elt) && is_expired('friday'))"
                >
                  <v-icon class="primary--text font-weight-bold mx-auto" large>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: "friday"
                        })
                      }}</span>
                  </span>

                <span class="ml-2" style="font-size:10px">{{
                    price({
                      uuid: elt.uuid,
                      day: "friday"
                    }) | currency
                  }}</span>
              </v-col>
              <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    small
                    tile
                    width="25"
                    @click="selectItem(elt , 'friday')"
                    :disabled="(hasAllergen(elt) || is_expired('friday')) || (hasAllergen(elt) && is_expired('friday'))"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:item.saturday="{item}" class="align-top" style="min-height: 400px !important;">
        <v-card
            v-for="(elt ,i) in item['saturday']"
            :key="i"
            class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
            elevation="0"
            min-width="100%"
            outlined
            v-bind:class="is_selected({
              item: elt,
              day: 'saturday'
            })? 'border-success' : ''"
        >

          <v-btn
              :disabled="(hasAllergen(elt) || is_expired('saturday')) || (hasAllergen(elt) && is_expired('saturday'))"
              @click="selectChanged(false , elt , 'saturday')" width="20" height="20"
              outlined class="pa-0 px-0"
              style="position:absolute;top:2px;left: 2px"
              color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: 'saturday'
            })">
            <v-icon class="ma-0" small>mdi-check
            </v-icon>
          </v-btn>

          <v-btn
              :disabled="(hasAllergen(elt) || is_expired('saturday')) || (hasAllergen(elt) && is_expired('saturday'))"
              @click="selectChanged(true , elt , 'saturday')" width="20" height="20"
              outlined class="pa-0 px-0"
              style="position:absolute;top:2px;left: 2px"
              color="grey" elevation="0" fab x-small v-else>
          </v-btn>
          <h6
              class="font-weight-bolder mt-0 font-size-md mx-auto"
              style="position:absolute;top:3px;right: 4px; font-family: Impact;"
          >
            {{
              elt.price | currency
            }}
          </h6>
          <v-card-text class="pa-2 mt-5">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col
                  class="pb-0 pointer text-left pt-1 ma-0"
                  cols="12"
              >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                <div class="text-muted">
                  {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                </div>
              </v-col>

              <v-col class="text-right d-flex">
                <v-spacer/>
                <v-btn
                    class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                    height="22"
                    icon
                    small
                    tile
                    width="22"
                    @click="showAllergen(elt)"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-information-variant
                  </v-icon>
                </v-btn>

                <div v-if="Array.isArray(elt.types)">

                  <v-btn v-for="type in elt.types" :key="type.name"
                         class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                         height="22"
                         icon
                         small
                         tile
                         width="22"
                  >
                    <v-icon class="primary--text pa-3 bg-grey">
                      mdi-{{ type.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    tile
                    small
                    width="25"
                    @click="minus({
                    item: elt , day: 'saturday'
                    })"
                    :disabled="(hasAllergen(elt) || is_expired('saturday')) || (hasAllergen(elt) && is_expired('saturday'))"
                >
                  <v-icon class="primary--text font-weight-bold mx-auto" large>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: "saturday"
                        })
                      }}</span>
                  </span>

                <span class="ml-2" style="font-size:10px">{{
                    price({
                      uuid: elt.uuid,
                      day: "friday"
                    }) | currency
                  }}</span>
              </v-col>
              <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                <v-btn
                    class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                    height="25"
                    icon
                    small
                    tile
                    width="25"
                    @click="selectItem(elt , 'saturday')"
                    :disabled="(hasAllergen(elt) || is_expired('saturday')) || (hasAllergen(elt) && is_expired('saturday'))"
                >
                  <v-icon class="primary--text pa-3 bg-grey">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:footer class="border-top">
        <div style="border-top: 1px solid gray;" class="mt-3 pt-2">
          <v-alert v-if="(user.net_credit - currentWeekTotal) < 0" dismissible color="warning" class="white--text">
            Please refill your account to order
          </v-alert>
          <v-btn
              :loading="loading"
              :disabled="loading || (user.net_credit - currentWeekTotal) < 0"
              elevation="0"
              color="success"
              block
              large
              @click="saveOrder"
          >
            {{ $t('generic.lang_saveThisWeek') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>


    <!--mobile menu-->
    <v-expansion-panels v-else accordion mandatory>
      <v-expansion-panel
          v-for="(header,i) in headers"
          :key="i"
      >
        <v-expansion-panel-header>
          <div>
            <strong>{{ header.text }}</strong> {{ header.date }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <div>
            <v-alert class="pb-1 pt-1 mb-1 mt-0" color="warning" dense outlined>
              Stornofrist {{ getDateToOrder(header.date) }}
            </v-alert>
            <v-alert class="pb-1 pt-1 mt-0" color="error" dense outlined>
              Bestellfrist {{ getDateToCancel(header.date) }}
            </v-alert>
          </div>
          <!--mobile items-->
          <div :key="j" v-for="(elt , j) in filteredItems[0][header.value.toLowerCase()]">
            <v-card
                :key="i"
                class="pl-1 mt-2  text-black pr-1 ma-0  image_size"
                elevation="0"
                min-width="100%"
                outlined
                rounded
                v-bind:class="is_selected({
              item: elt,
              day: header.value.toLowerCase()
            })? 'border-success' : ''"
            >
              <v-btn
                  :disabled="(hasAllergen(elt) || is_expired(header.value.toLowerCase())) || (hasAllergen(elt) && is_expired(header.value.toLowerCase()))"
                  @click="selectChanged(false , elt , 'monday')"
                  width="20" height="20"
                  outlined class="pa-0 px-0"
                  style="position:absolute;top:2px;left: 2px"
                  color="success" elevation="0" fab x-small v-if="is_selected({
              item: elt,
              day: header.value.toLowerCase()
            })">
                <v-icon class="ma-0" small>mdi-check
                </v-icon>
              </v-btn>

              <v-btn
                  :disabled="(hasAllergen(elt) || is_expired(header.value.toLowerCase())) || (hasAllergen(elt) && is_expired(header.value.toLowerCase()))"
                  @click="selectChanged(true , elt , header.value.toLowerCase())"
                  width="20" height="20"
                  outlined class="pa-0 px-0"
                  style="position:absolute;top:2px;left: 2px"
                  color="grey" elevation="0" fab x-small v-else>
              </v-btn>


              <h6
                  class="font-weight-bolder mt-0 font-size-md mx-auto"
                  style="position:absolute;top:3px;right: 4px; font-family: Impact;"
              >
                {{
                  elt.price | currency
                }}
              </h6>
              <v-card-text class="pa-2 mt-5">
                <v-row class="pa-0 ma-0" no-gutters>
                  <v-col
                      class="pb-0 pointer text-left pt-1 ma-0"
                      cols="12"
                  >
                                  <span :style="{color: elt.shortname.color}"
                                        class="d-inline-block font-weight-bolder">{{ elt.shortname.name }}</span>
                    <span class="mobile_extras_name  font-weight-bold primary--text ml-4">
                    {{ elt.alias || 'Unknown' }}
                  </span>
                    <div class="text-muted">
                      {{ elt.item.description && elt.item.description.substring(0, 20) || '' }}
                    </div>
                  </v-col>

                  <v-col class="text-right d-flex">
                    <v-spacer/>
                    <v-btn
                        class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                        height="22"
                        icon
                        small
                        tile
                        width="22"
                        @click="showAllergen(elt)"
                    >
                      <v-icon class="primary--text pa-3 bg-grey">
                        mdi-information-variant
                      </v-icon>
                    </v-btn>

                    <div v-if="Array.isArray(elt.types)">

                      <v-btn v-for="type in elt.types" :key="type.name"
                             class="pa-0 px-0 ma-1 primary lighten-4 rounded"
                             height="22"
                             icon
                             small
                             tile
                             width="22"
                      >
                        <v-icon class="primary--text pa-3 bg-grey">
                          mdi-{{ type.icon }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="ma-0 mx-auto pr-5 pl-5" style="max-width: calc(100% - 60px) !important;"/>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                    <v-btn
                        class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                        height="25"
                        icon
                        tile
                        small
                        width="25"
                        :disabled="(hasAllergen(elt) || is_expired(header.value.toLowerCase())) || (hasAllergen(elt) && is_expired(header.value.toLowerCase()))"
                        @click="minus({
                    item: elt , day: header.value.toLowerCase()
                    })"
                    >
                      <v-icon class="primary--text font-weight-bold mx-auto" large>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </v-col>

                  <v-col class="pointer  text-center  mx-auto pt-0 pb-1" cols="6">
                  <span class="mt-0 font-size-lg text-muted mx-auto">
                    <span class="font-weight-bold">x{{
                        quantity({
                          item: elt,
                          day: header.value.toLowerCase()
                        })
                      }}</span>
                  </span>

                    <span class="ml-2" style="font-size:10px">{{
                        price({
                          uuid: elt.uuid,
                          day: header.value.toLowerCase()
                        }) | currency
                      }}</span>
                  </v-col>
                  <v-col class="mx-auto text-right pointer  pa-0" cols="3">
                    <v-btn
                        class="pa-0 px-0 ma-0 primary lighten-3 rounded"
                        height="25"
                        icon
                        small
                        tile
                        width="25"
                        @click="selectItem(elt , header.value.toLowerCase())"
                        :disabled="(hasAllergen(elt) || is_expired(header.value.toLowerCase())) || (hasAllergen(elt) && is_expired(header.value.toLowerCase()))"
                    >
                      <v-icon class="primary--text pa-3 bg-grey">
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="adaldialog" max-width="600" scrollable persistent>
      <v-card>
        <v-card-title class="pa-0 card-header">
          <v-tabs v-model="tabs" color="primary">
            <v-tab>{{ $t('generic.lang_additives') }}</v-tab>
            <v-tab>{{ $t('generic.lang_allergies') }}</v-tab>
          </v-tabs>
        </v-card-title>
        <v-tabs-items v-if="selectedElement != null" v-model="tabs">
          <v-tab-item>
            <v-list>
              <v-list-item v-for="(additive , i) in this.selectedElement.item.additives" :key="i">
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-check
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ additive.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <v-tab-item>
            <v-list>
              <v-list-item v-for="(allergen , i) in this.selectedElement.item.allergens" :key="i">
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-check
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ allergen.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
        <v-card-text/>

        <v-card-actions class="card-footer text-right">
          <v-spacer/>
          <v-btn text color="primary" class="mx-auto" @click="closeAllergen">
            {{ $t('generic.lang_accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <meal-type-dialog v-bind:dialog="typesDialog" v-model="typesDialog" v-bind:types="this.selectedTypes"/>
  </v-container>
</template>

<script>
/**
 * import components
 * */
import MealTypeDialog from "@/components/dashboard/Menu/MealTypeDialog";
/**
 * import plugins
 * */
import {createNamespacedHelpers} from 'vuex'
import ENDPOINTS from "@/plugins/axios/endpoints";


export default {
  name: 'MenuDataTable',
  components: {MealTypeDialog},
  props: {
    items: Array,
    orders: Array,
    allergens: Array,
    curr: Date,
    firstDay: String,
  },
  data: () => {
    return {
      tabs: 0,
      loading: false,
      adaldialog: false,
      typesDialog: false,
      selectedElement: null,
      selectedTypes: [],
      errorMessage: "",
      errorVisible: false,
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    }
  },
  computed: {
    ...createNamespacedHelpers('config').mapGetters([
      'getTimes'
    ]),

    ...createNamespacedHelpers('auth').mapGetters({
      user: "getUser"
    }),
    ...createNamespacedHelpers('order').mapGetters([
      'quantity',
      "price",
      "total",
      "is_selected",
      "order_request"
    ]),
    currentWeekTotal() {
      return this.total - this.oldTotal;
    },
    oldTotal() {
      if (Array.isArray(this.orders) && this.orders.length > 0) {
        return this.orders.reduce((total, order) => total + order.total, 0);
      } else return 0
    },
    /**
     * check if date is expired
     *@param day (string)
     * */
    is_expired() {
      return (day) => {
        /**
         * calc unix timestamp
         * */
        const tmpDT = new Date(this.firstDay);
        const day_index = this.weekDays.indexOf(
            this.weekDays.find((elt) => elt.toLowerCase() === day.toLowerCase())
        );

        tmpDT.setDate(tmpDT.getDate() + day_index);
        tmpDT.setHours(0, 0, 0, 0)
        tmpDT.setMinutes(tmpDT.getMinutes() + parseInt(this.times.deadlines.to_cancel))
        return ((new Date(tmpDT.toDateString()).getTime()) <= (new Date().getTime()))
      }
    },
    /**
     * check if user have allergens from specific items
     * @param item ({})
     * */
    hasAllergen() {
      const self = this;
      return (item) => {
        //item allergens
        if (!Array.isArray(item.item.allergens)) return false;
        const item_allergens = item.item.allergens.map((elt) => elt.id)

        //user allergens
        if (!Array.isArray(self.allergens)) return false;
        const user_allergens = self.allergens.map((elt) => elt.id)

        //get common items between allergens
        const res = user_allergens.filter((elt) => item_allergens.includes(elt));
        return (Array.isArray(res) && res.length > 0)

      }
    },
    times() {
      return this.getTimes
    },
    headers() {
      const days = []
      for (let i = 0; i < 6; i++) {
        days.push({
          text: this.getDate(i + 1).split(' ')[0],
          date: this.getDate(i + 1).split(' ')[1],
          value: this.weekDays[i].toLowerCase(),
          align: 'center',
          sortable: false
        })
      }
      return days
    },

    /**
     * format items to respect rendering structure
     * */
    filteredItems() {
      const menuItems = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
      }

      if (this.items !== null && this.items !== undefined && this.items !== '' && Array.isArray(this.items)) {
        this.items.forEach((elt) => {
          let excludedDates = []
          if (elt.excludedDates) {
            excludedDates = JSON.parse(elt.excludedDates).map((d) => {
              if (new Date(this.firstday).getTime() <= (d * 1000) && new Date(this.lastday).getTime() >= (d * 1000)) {
                const dt = new Date(parseInt(d * 1000))
                return this.weekDays[dt.getDay() - 1]
              }
            })
          }
          if (elt.calendar.days !== null && elt.calendar.days !== undefined && elt.calendar.days !== '' && elt.calendar.days.length > 0) {
            elt.calendar.days.forEach((d) => {
              if (!excludedDates.includes(d) && menuItems[d.toLowerCase()]) {
                menuItems[d.toLowerCase()].push(elt)
              }
            })
          }
        })
      }

      return [menuItems]
    }
  },
  watch: {},

  methods: {
    /*map order module actions*/
    ...createNamespacedHelpers('order').mapActions([
      'addToCard'
    ]),
    /*map order  module mutations */
    ...createNamespacedHelpers('order').mapMutations([
      'minus',
      "remove",
      "push",
      "clear"
    ]),
    /**
     * format ordered items to respect rendering structure
     * */
    addOldOrders(orders) {
      if (orders && Array.isArray(orders) && orders.length > 0) {
        this.clear()
        orders.forEach((elt) => {
          const dt = new Date(parseInt(elt.date) * 1000)
          elt.items.forEach((item) => {
            //for (let i = 1; i <= parseInt(item.quantity); i++) {
            this.push({
              day: (this.weekDays[dt.getDay() - 1]).toLowerCase(),
              quantity: item.quantity,
              timestamp: parseInt(elt.date), // date already returned in unix timestamp
              item: item
            });
            //}
          })
        })

        this.$forceUpdate();
      } else this.clear();

    },
    /**
     * open meal types dialog
     * */
    openTypes(elt) {
      let tmp = Object.assign({}, elt);

      if (Array.isArray(tmp.types))
        this.selectedTypes = tmp.types
      else
        this.selectedTypes = []
      this.typesDialog = true
    },
    getDate(i) {
      let dt = new Date(this.curr.getTime())
      const first = dt.getDate() - dt.getDay()
      dt.setDate(first)
      dt = new Date(dt.getTime() + (i * 24 * 60 * 60 * 1000));
      return this.$moment(dt).format("dddd") + ' ' + this.$moment(dt).format("YYYY-MM-DD");
    },
    /***
     * SAVE SELECTED ITEMS FOR THIS WEEK
     * */
    saveOrder() {
      this.loading = true;

      this.$axios.post(ENDPOINTS.CANTEEN.ORDER.CREATE, {
        days: this.order_request
      }).then(() => {
        this.$store.commit('alert/show', {
          message: 'your order is being processed',
          color: 'success',
          visible: true
        })
      }).catch((err) => {

        if (err.response && err.response.data.message) {
          this.$store.commit('alert/show', {
            message: err.response.data.message,
            color: 'error',
            visible: true
          })
          this.errorMessage = err.response.data.message;
          this.errorVisible = true;

          this.$vuetify.goTo(0);
        } else {
          this.$store.commit('alert/show', {
            message: '',
            color: 'success',
            visible: true
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    closeAllergen() {
      this.selectedElement = null
      this.adaldialog = false
    },
    showAllergen(elt) {
      this.selectedElement = elt
      this.adaldialog = true
    },
    selectChanged(val, item, day) {
      if (val)
        this.selectItem(item, day)
      else
        this.remove(
            {
              item: item,
              day: day
            }
        );

      this.$forceUpdate();
    },
    /**
     * format selected date to be like:
     * */
    getDay(i) {
      let dt = new Date(this.curr.getTime())
      const first = dt.getDate() - dt.getDay()
      dt.setDate(first)
      dt = new Date(dt.getTime() + (i * 24 * 60 * 60 * 1000))
      return dt.toDateString().split(' ')[0] + ' ' + dt.toDateString().split(' ')[2]
    },
    getDateToOrder(date) {
      let dt = this.$moment(date);
      if (this.times.deadlines)
        dt = dt.add(-parseInt(this.times.deadlines.to_order), 'minutes').format('YYYY-MM-DD HH:mm');
      return dt;
      /*
      const dt = new Date(date.split('-').reverse().join('-'))
      dt.setHours(0, 0, 0, 0)
      dt.setMinutes(dt.getMinutes() - parseInt(this.times.deadlines.to_order))

      return dt.toDateString() + ' ' + dt.toLocaleTimeString().substr(0, 5)*/
    },
    /**
     * */
    getDateToCancel(date) {

      let dt = this.$moment(date);
      if (this.times.deadlines)
        dt = dt.add(parseInt(this.times.deadlines.to_cancel), 'minutes').format('YYYY-MM-DD HH:mm');
      return dt;
      /*  const dt = new Date(date.split('-').reverse().join('-'))
        dt.setHours(0, 0, 0, 0)
        if (this.times.deadlines)
          dt.setMinutes(dt.getMinutes() + parseInt(this.times.deadlines.to_cancel))

        return dt.toDateString() + ' ' + dt.toLocaleTimeString().substr(0, 5)*/
    },
    /***
     * @param item ({})
     * @param day ("")
     */
    selectItem(item, day) {
      /**
       * calc unix timestamp
       * */
      const tmpDT = new Date(this.replaceMonth(this.firstDay))


      const day_index = this.weekDays.indexOf(
          this.weekDays.find((elt) => elt.toLowerCase() === day.toLowerCase())
      );
      tmpDT.setDate(tmpDT.getDate() + day_index);

      /**
       * add @item to card
       * */
      this.addToCard({
        timestamp: this.$moment(tmpDT).unix(),
        day: day,
        item: item
      })
    },
  },
  mounted() {
    this.addOldOrders(this.orders);
  }
}
</script>

<style>
.dt td {
  vertical-align: top !important;
}

* {
  user-select: none;
}
</style>
