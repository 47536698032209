<template>
  <v-dialog max-width="600" v-model="open" scrollable overlay-color="primary">
    <v-card>
      <v-card-title class="card-header">
        {{$t('generic.lang_mealTypes')}}
      </v-card-title>
      <v-card-text class="pa-6">
        <v-row align="center">
          <v-col v-for="type in this.types" :key="type.uuid" cols="12" sm="6" md="4">
            <v-card outlined>
              <v-card-text>
                <v-icon x-large>
                  mdi-{{ type.icon }}
                </v-icon>
              </v-card-text>
              <v-card-text class="pa-0">
                {{ type.name }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="card-footer text-right">
        <v-spacer/>
        <v-btn text color="primary" class="mx-auto" @click="close">
          {{$t('generic.lang_accept')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MealTypeDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    types:
        {
          type: Array,
          required: true
        }
  },
  computed: {
    open: {
      get() {
        return this.dialog
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods:
      {
        close() {
          this.$emit('input', false)
        }
      }
}
</script>

<style scoped>

</style>
